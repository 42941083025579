<template>
    <div class="container">
        <div class="parallax"></div>

        <section class="career text-center d-flex flex-column align-items-center justify-content-center" id="career">
            <template>
                <v-container>
                    <v-breadcrumbs :items="items">
                        <template v-slot:item="{ item }"
                        >
                            <v-breadcrumbs-item
                                :href="item.href"
                                :disabled="item.disabled"
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-container>
            </template>


            <h4 class="title-section d-block">Our Office</h4>
            <section class="contact-us text-center d-flex flex-column align-items-center justify-center">
                <v-container class="pt-0">
                    <v-row>
                        <v-col
                            xl="6"
                            lg="6"
                            md="12"
                            sm="12"
                            xs="12"
                        >
                            <iframe frameborder="0" style="border:0; min-width:100px; width: 100%; height: 100%;" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJk97JMn_3aS4RvgWFZ2D7Qnw&key=AIzaSyDf96in6sN12InkBcoHejx09iOmMgqhwnk" allowfullscreen></iframe>
                        </v-col>
                        <v-col
                            xl="6"
                            lg="6"
                            md="12"
                            sm="12"
                            xs="12"
                        >
                            <div class="contact-sub border-gradient-brand mx-auto">
                                <h4 class="title-contact mb-4 d-none d-sm-block">Get In Touch</h4>
                                <div class="contact-sub-detail">
                                    <div class="contact-child mx-auto my-auto mt-3">
                                        <v-img
                                            class="icon-location contact-icon d-md-inline-block d-xs-block mx-auto"
                                            src="/images/icon-location.png"
                                            max-width="20">
                                        </v-img>
                                        <label class="contact-address d-inline-block ml-4">Xylo Indonesia<br> {{ address }}</label>
                                    </div>
                                    <div class="contact-child mt-3">
                                        <v-img
                                            class="contact-icon d-md-inline-block d-xs-block mx-auto"
                                            src="/images/icon-call.png"
                                            max-width="20">
                                        </v-img>
                                        <label class="ml-4">{{ phone_number }}</label>
                                    </div>
                                    <div class="contact-child mx-auto my-auto mt-3">
                                        <v-img
                                            class="contact-icon d-md-inline-block d-xs-block mx-auto"
                                            src="/images/icon-email.png"
                                            max-width="20">
                                        </v-img>
                                        <label class="ml-4">{{ email }}</label>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <div class="my-12"></div>

            <FormContact class="mb-5"></FormContact>

            <div class="my-12"></div>

        </section>
    </div>
</template>
<script>
    import api from "../../api/property";
    import FormContact from "./homepage/FormContact";

    export default {
        data: () => ({
            address: '',
            phone_number: '',
            email: '',
            items: [
                {
                    text: 'Home',
                    disabled: false,
                    href: '/',
                },
                {
                    text: 'Contact',
                    disabled: true,
                    href: '#',
                },
            ],
        }),
        created() {
            api.getData("contact")
                .then(response => {
                    this.address = response.data.data.options.address
                    this.phone_number = response.data.data.options.phone_number
                    this.email = response.data.data.options.email
                })
                .catch(err => {
                    console.log(err)
                });
        },
    }
</script>


<style>
   #breadcrumbs{
       padding-left: -14px;
   }
</style>

<template>
    <v-app>
        <Header></Header>

        <v-content>

            <div>
                <About></About>

                <div class="parallax"></div>

                <Solution></Solution>
                <Tools></Tools>
                <StudyCase></StudyCase>
                <Contact></Contact>
                <FormContact></FormContact>
            </div>
        </v-content>

        <Footer></Footer>
    </v-app>


</template>
<script>
    import Header from "../../../components/HeaderLayouts"
    import Footer from "../../../components/FooterLayouts"
    import About from "./About";
    import Solution from "./Solution";
    import Tools from "./Tools";
    import StudyCase from "./StudyCase";
    import Contact from "./ContactUs";
    import FormContact from "./FormContact";

    export default {
        components: {
            Header,
            Footer,
            About,
            Solution,
            Tools,
            StudyCase,
            Contact,
            FormContact
        }
    }
</script>

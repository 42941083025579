<template>
    <section class="study-case snap-child text-center d-flex flex-column align-items-center justify-content-center" id="studycase">
        <h4 class="title-section">Study Case</h4>
        <v-container>
            <div class="studycase-items mx-auto">
                <a
                    v-for="(item, index) in study_cases"  v-if="index <= 4" :key="item.slug"
                    :href='/studycase/ + item.slug'
                    :title='item.slug'
                    class="d-inline-block"
                >
                    <v-img class="img-studycase-sm" max-width="239" v-bind:src="item.thumbnail" v-bind:alt="item.name"></v-img>
                </a>

            </div>

            <v-btn
                v-if="study_cases.length"
                class="btn-studycase mx-auto"
                style="color: #ffffff"
                :href='/studycase/ + study_cases[0].slug'
                :title='study_cases[0].slug'
            >
                VIEW ALL
            </v-btn>
        </v-container>
    </section>
</template>
<script>
    export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Study Case',
            // all titles will be injected into this template
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data() {
            return {
                study_cases: []
            }
        },
        methods: {
            fetchData: function (page_url) {
                axios
                    .get(page_url || '/api/studycases/list', { params: {} })
                    .then(response => {
                        this.study_cases = response.data.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
        },
        mounted() {
            this.fetchData();
        },
    }
</script>

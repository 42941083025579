<template>
    <section class="studycase-detail snap-child">
        <v-row>
            <v-img :src="study_case.background">
                <v-container>
                    <v-breadcrumbs :items="breadcrumbs">
                        <template v-slot:item="{ item }"
                        >
                            <v-breadcrumbs-item
                                :href="item.href"
                                :disabled="item.disabled"
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>

                    <v-row
                        align="center"
                        class="white--text mx-auto"
                        justify="center"
                    >
                        <v-col
                            class="white--text text-center"
                            cols="12"
                            tag="h1"
                        >
                            <span
                                class="title-section"
                            >
                              Study Case
                            </span>

                            <v-img
                                width="265"
                                max-height="108"
                                :src="study_case.logo"
                                class="mx-auto my-4"
                            >
                            </v-img>

                            <v-responsive
                                class="mx-auto title font-weight-light mb-8"
                                max-width="635"
                                :class="[$vuetify.breakpoint.smAndDown ? 'body-2' : 'body-1']"
                            >
                                <p style="white-space: pre-line">{{ study_case.challenge }}</p>
                                <p style="white-space: pre-line">{{ study_case.services }}</p>
                            </v-responsive>

                            <a
                                href="/#contact"
                                title="Contact"
                                class="btn-studycase-link"
                            >
                                {{ ads_text[Math.floor(Math.random() * ads_text.length)] }}
                            </a>

                        </v-col>
                    </v-row>
                </v-container>
            </v-img>
        </v-row>

        <v-row class="mt-5 my-5">
            <v-container class="mx-auto">
                <div v-if="study_cases.length" class="col-md-12 studycase-child mx-auto my-auto d-flex text-center">
                    <span @click="showPrev" class="slick-arrow-custom float-left my-auto">
                        <v-img
                            src="/images/arrow-prev.png"
                            max-height="43"
                            max-width="32"
                        >
                        </v-img>
                    </span>
                    <VueSlickCarousel v-bind="settings" ref="carousel" class="my-auto">
                        <router-link
                            v-for="(item, index) in study_cases" :key="item.slug"
                            :to="'/studycase/' + item.slug"
                            :title='item.slug'
                            class="d-inline-block my-auto">
                            <div class="img-studycase-sm mx-auto py-auto">
                                <v-img
                                    v-bind:src="item.thumbnail"
                                >
                                </v-img>
                            </div>
                        </router-link>
                    </VueSlickCarousel>
                    <span @click="showNext" class="slick-arrow-custom float-right my-auto">
                        <v-img
                            src="/images/arrow-next.png"
                            max-height="43"
                            max-width="32"
                        >
                        </v-img>
                    </span>
                </div>
            </v-container>
        </v-row>
    </section>

</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel/dist/vue-slick-carousel.umd.min'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import api from '../../api/studycase';

    export default {
        name:'StudyCaseDetail',
        components: { VueSlickCarousel },
        metaInfo: {
            title: 'Study Case',
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data() {
            return {
                ads_text: [
                    "Contact us to discuss further on your requirements",
                ],
                study_cases: [],
                study_case: [],
                settings:{
                    "dots": false,
                    "arrows": false,
                    "dotsClass": "slick-dots custom-dot-class",
                    "edgeFriction": 0.35,
                    "infinite": true,
                    "speed": 500,
                    "slidesToShow": 4,
                    "slidesToScroll": 1,
                    "responsive": [
                        {
                            "breakpoint": 1024,
                            "settings": {
                                "slidesToShow": 4,
                                "slidesToScroll": 4,
                                "infinite": true,
                                "dots": true
                            }
                        },
                        {
                            "breakpoint": 600,
                            "settings": {
                                "slidesToShow": 2,
                                "slidesToScroll": 1,
                                "initialSlide": 1
                            }
                        },
                        {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 2,
                                "slidesToScroll": 1
                            }
                        }
                    ]
                },
                breadcrumbs: [
                    {
                        text: 'Home',
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: 'Studycase',
                        disabled: true,
                        href: '#',
                    },
                ],
            }
        },
        methods: {
            fetchDetail: function (page_url) {
                api.find(this.$route.params.id)
                    .then(response => {
                        this.study_case = response.data.data[0];
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            fetchData: function (page_url) {
                axios
                    .get(page_url || '/api/studycases/list', { params: {} })
                    .then(response => {
                        this.study_cases = response.data.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            showNext() {
                this.$refs.carousel.next()
            },
            showPrev() {
                this.$refs.carousel.prev()
            },
        },
        mounted() {
            this.fetchDetail();
            this.fetchData();
        },
        watch: {
            // call again the method if the route changes
            '$route': 'fetchDetail'
        },
    }
</script>

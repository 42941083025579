import axios from 'axios';

export default {
    all() {
        return axios.get('/api/studycases/list');
    },
    find(id) {
        return axios.get(`/api/studycases/show/${id}`);
    },
};

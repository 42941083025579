<template>
    <section class="our-solution snap-child text-center d-flex flex-column align-items-center justify-center" id="solutions">
        <div v-if="solutions.length" class="bg-gradiant">
            <p class="title-solution">Solutions From Us</p>
            <div class="content-solution d-flex">
                <span @click="showPrev" class="slick-arrow-custom float-left my-auto d-md-none">
                    <v-img
                        src="/images/arrow-prev-white.png"
                        max-height="41"
                        max-width="35">
                    </v-img>
                </span>
                    <VueSlickCarousel v-bind="settings" ref="carousel">
                        <div v-for="solution in solutions" :key="solution.id" class="card card-solution text-center">
                            <div class="card-body px-4">
                                <v-img
                                    :src="solution.image.path"
                                    class="mx-auto"
                                    max-width="85"
                                    max-height="74">
                                </v-img>
                                <h2 class="card-title subtitle-solution">{{ solution.title }}</h2>
                                <h3 class="description-solution">{{ solution.description }}</h3>
                            </div>
                        </div>
                    </VueSlickCarousel>
                <span @click="showNext" class="slick-arrow-custom float-right my-auto d-md-none">
                    <v-img
                        src="/images/arrow-next-white.png"
                        max-height="41"
                        max-width="35">
                    </v-img>
                </span>
            </div>
        </div>
    </section>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel/dist/vue-slick-carousel.umd.min'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Our Solutions',
            // all titles will be injected into this template
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        name: 'SolutionCarousel',
        components: { VueSlickCarousel },
        data() {
            return {
                solutions: [],
                settings:{
                    "dots": false,
                    "arrows": false,
                    "dotsClass": "slick-dots custom-dot-class",
                    "infinite": true,
                    "speed": 500,
                    // "centerMode":true,
                    "slidesToShow": 3,
                    "slidesToScroll": 1,
                    "responsive": [
                        {
                            "breakpoint": 1024,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 1,
                                "infinite": true,
                                "dots": true
                            }
                        },
                        {
                            "breakpoint": 600,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 1,
                                "initialSlide": 2
                            }
                        },
                        {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1
                            }
                        }
                    ]
                },
            }
        },
        methods: {
            showNext() {
                this.$refs.carousel.next()
            },
            showPrev() {
                this.$refs.carousel.prev()
            },
            fetchData: function (page_url) {
                axios
                    .get(page_url || '/api/solutions/list', { params: {} })
                    .then(response => {
                        this.solutions = response.data.data
                    })
                    .catch(error => {
                        // console.log(error)
                    })
            },

        },
        mounted() {
            this.fetchData();
        },
    }
</script>

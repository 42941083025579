<template>
    <div class="container">
        <div class="parallax"></div>

        <section class="d-flex flex-column">
                <h4 class="mx-auto title-blog" align="center" style="font-family: 'Montserrat ExtraBold' !important; font-size: 20px">Blog</h4>

                <v-container v-if="$vuetify.breakpoint.smAndUp">
                    <v-row>
                        <v-col
                                v-for="(item, i) in items"
                                :key="i"
                                cols="12"
                        >
                            <v-card
                                    outlined
                                    color="transparent"
                                    align="left"
                            >
                                <div class="d-flex flex-no-wrap justify-space-between">
                                    <div>
                                        <v-img
                                                :src="item.image"
                                                max-width="494"
                                                max-height="263"
                                        ></v-img>
                                    </div>
                                    <div>
                                        <v-card-subtitle
                                                class="py-0"
                                                style="font-family: 'Montserrat Regular'; font-size: 15px"
                                                v-text="item.publish_at">
                                        </v-card-subtitle>

                                        <v-card-title
                                                class="headline"
                                                v-text="item.title"
                                                style="font-family: 'Montserrat ExtraBold' !important; font-size: 25px !important; max-width: 576px"
                                        ></v-card-title>

                                        <v-card-text style="font-family: 'Montserrat Regular' !important; font-size: 15px; margin-bottom: 5px;">
                                            <div>{{ item.description }}</div>
                                        </v-card-text>

                                        <v-card-actions >
                                            <v-btn
                                                    tile
                                                    class="btn-studycase"
                                                    style="margin-left: 9px;"
                                            >READ MORE</v-btn>
                                        </v-card-actions>
                                    </div>
                                </div>
                            </v-card>

                        </v-col>
                    </v-row>

                    <v-row style="padding-top: 139px;">
                        <v-col style="margin-bottom: 141px" cols="4" v-for="n in 6" :key="n">
                            <v-card
                                    align="left"
                                    color="black"
                                    class="mx-auto"
                                    max-width="400"
                            >
                                <v-img
                                        class="white--text align-end"
                                        height="200px"
                                        src="images/blog/visit-mini.png"
                                >
                                </v-img>

                                <v-card-subtitle
                                        align="left"
                                        class="pb-0"
                                        style="font-family: 'Montserrat Regular' !important;font-size: 15px;"
                                >29/10/2020
                                </v-card-subtitle>

                                <v-card-text class="text--primary">
                                    <div style="font-family: 'Montserrat ExtraBold' !important;font-size: 17px;">COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITAS MERCU BUANA</div>
                                </v-card-text>

                                <v-card-actions>
                                    <v-card-actions>
                                        <v-btn
                                                tile
                                                class="btn-studycase"
                                        >READ MORE</v-btn>
                                    </v-card-actions>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>


            <!--Mobile-->

            <v-container v-if="$vuetify.breakpoint.xs">
                <!--<v-row>-->
                    <v-col
                            v-for="(item, i) in items"
                            :key="i"
                            cols="12"
                    >
                        <v-card
                                outlined
                                color="transparent"
                                align="left"
                        >
                            <!--<div class="d-flex flex-no-wrap justify-space-between">-->
                                <div>
                                    <v-card-subtitle
                                            class="py-0"
                                            v-text="item.publish_at">
                                    </v-card-subtitle>

                                    <v-card-title
                                            class="headline"
                                            v-text="item.title"
                                            style="max-width: 511px"
                                    ></v-card-title>
                                </div>
                                <div>
                                    <v-img
                                            :src="item.image"
                                            max-width="494"
                                            max-height="263"
                                    ></v-img>
                                </div>
                                <div>
                                    <v-card-text style="margin-bottom: 28px;">
                                        <div>{{ item.description }}</div>
                                    </v-card-text>

                                    <v-card-actions >
                                        <v-btn
                                                tile
                                                class="mx-auto btn-studycase"
                                        >READ MORE</v-btn>
                                    </v-card-actions>
                                </div>
                            <!--</div>-->
                        </v-card>

                    </v-col>
                <!--</v-row>-->

                <v-row style="padding-top: 20px;">
                    <v-col style="" cols="6" v-for="n in 4" :key="n">
                        <v-card
                                align="left"
                                color="black"
                                class="mx-auto"
                                max-width="156"
                        >
                            <v-img
                                    class="white--text align-end"
                                    height="83px"
                                    src="images/blog/visit-mini.png"
                            >
                            </v-img>

                            <v-card-subtitle
                                    align="left"
                                    class="pb-0"
                                    style="font-size: 11px;"
                            >29/10/2020
                            </v-card-subtitle>

                            <v-card-text class="text--primary">
                                <div style="font-size: 11px;">COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITAS MERCU BUANA</div>
                            </v-card-text>

                            <v-card-actions>
                                <v-card-actions>
                                    <v-btn
                                            tile
                                            class="btn-studycase"
                                            style="width: 158px !important; margin-left: -15px !important;"
                                    >READ MORE</v-btn>
                                </v-card-actions>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>



        </section>
    </div>

</template>

<script>
    export default {
        name: "Blog",
        metaInfo: {
            title: 'Blog',
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data(){
            return {
                items:[
                    {
                        image: 'images/blog/visit-mini.png',
                        publish_at: '31/03/2020',
                        title: 'COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITAS MERCU BUANA',
                        description: 'Melengkapi program MARGOCY (Marcomm Goes to Agency), Bounche dipilih untuk menjelaskan tema Learn How to Develop Skills in The Field of Digital Marketing.',
                        slug: '',
                    }
                ],
            }
        }
    }
</script>

<style scoped>

</style>

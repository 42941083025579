/**
 //  STORE
 **/
const state = {
    users: [{id: 12, name: "John Doe"},{id: 13, name: "Janet Jackson"}],
    activeUserId: null,
}
const mutations = {
    setActiveUser: (state, payload) => {
        state.activeUserId = payload.id
    }
}
const getters = {
    activeUser: ({users, activeUserId}) => {
        return users.find((u) => u.id === activeUserId) || null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}

const Profile =  {
    created() { this.loadData() },
    watch: {
        $route() { this.loadData() }
    },
    computed: {
        user() { return this.$store.getters.activeUser }
    },
    methods: {
        loadData() {
            this.$store.commit('setActiveUser', {id: null})
            setTimeout(() => { // simulate async data fetching...
                this.$store.commit('setActiveUser', {id: this.$route.params.id})
            }, 2000)
        }
    },
    template: '<div v-if="user">Profile for: {{user.name}}</div>'
}

import { render, staticRenderFns } from "./ToolsDetail.vue?vue&type=template&id=1b94fee4&scoped=true&"
import script from "./ToolsDetail.vue?vue&type=script&lang=js&"
export * from "./ToolsDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b94fee4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VImg})

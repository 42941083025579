import Vue from 'vue';
import Router from 'vue-router';
import goTo from 'vuetify/es5/services/goto'
import VueMeta from 'vue-meta'

Vue.use(Router);
Vue.use(VueMeta);

const routes = [
        {
            path: '/',
            name: 'Home',
            component: () => import('../views/pages/homepage/index'),
            meta: { bcLinkText: 'Default Page'},
        },
        {
            path: '/',
            component: () => import('../views/Index'),
            children: [
                {
                    path: '/studycase/:id',
                    name: 'studycase',
                    component: () => import('../views/pages/StudyCaseDetail'),
                    meta: { bcLinkText: 'StudyCase'},
                },
                {
                    path: '/career',
                    name: 'career',
                    component: () => import('../views/pages/homepage/Career'),
                    meta: { bcLinkText: 'Career'},
                },
                {
                    path: '/blog',
                    name: 'blog',
                    component: () => import('../views/pages/homepage/Blog'),
                    meta: { bcLinkText: 'Blog'},
                },
                {
                    path: '/blog/:slug',
                    name: 'blog detail',
                    component: () => import('../views/pages/BlogDetail'),
                    meta: { bcLinkText: 'Blog Detail'},
                },
                {
                    path: '/about-itu-tentang-kami',
                    name: 'about',
                    component: () => import('../views/pages/AboutDetail'),
                    meta: { bcLinkText: 'About'},
                },
                {
                    path: '/solutions',
                    name: 'solutions',
                    component: () => import('../views/pages/SolutionDetail'),
                    meta: { bcLinkText: 'Solutions'},
                },
                {
                    path: '/tools',
                    name: 'tools',
                    component: () => import('../views/pages/ToolsDetail'),
                    meta: { bcLinkText: 'Tools'},
                },
                {
                    path: '/contact',
                    name: 'contact',
                    component: () => import('../views/pages/ContactDetail'),
                    meta: { bcLinkText: 'Contact'},
                },
                {
                    path: '/privacy-policy',
                    name: 'privacy',
                    component: () => import('../views/pages/homepage/PrivacyPolicy'),
                    meta: { bcLinkText: 'Contact'},
                },
                {
                    path: '/terms-of-service',
                    name: 'tos',
                    component: () => import('../views/pages/homepage/TermsOfService'),
                    meta: { bcLinkText: 'Contact'},
                },
                {
                    path: '/404',
                    component: () => import('../views/error/NotFound'),
                },
                { path: '*', redirect: '/404' },
            ]

        },
];


export default new Router({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    routes
});

<template>
    <router-view />
</template>

<script>
    export default {
        name: 'App',
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Xylo Indonesia',
            // all titles will be injected into this template
            titleTemplate: '%s | Digital and CRM Solutions for your business'
        }
    }
</script>


<template>
    <div>
        <v-app-bar
            app
            color="black"
            v-scroll="handleScroll"
        >
            <a class="" href="/" title="home" id="logo-header" v-if="showHeaderLogo">
                <v-img v-if="$vuetify.breakpoint.xs" src="/images/logo-menu-sm.png" class="ml-3" max-width="80" title="XYLO Logo" alt="XYLO Logo"></v-img>
                <v-img v-else src="/images/logo-menu-md.png" class="ml-3" max-width="80" title="XYLO Logo" alt="XYLO Logo"></v-img>
            </a>

            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>

            <v-app-bar-nav-icon @click="drawer = true" class="mr-3"></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            color="black"
            right
            class="text-center pt-12"
        >
            <a class="menu-logo d-inline-block" href="/" title="home">
                <v-img
                    src="/images/logo-menu-md.png"
                    max-width="116" title="XYLO Logo"
                    alt="XYLO Logo">
                </v-img>
            </a>
            <v-list
                nav
                dense
                class="text-right menu-border mt-12"
            >
                <v-list-item-group
                    v-model="group"
                    active-class="text--accent-4"
                >
                    <v-list-item @click="scroll('about')" @click.stop="drawer = !drawer">
                        <v-list-item-title>About</v-list-item-title>
                    </v-list-item>


                    <v-list-item :href="'/solutions'">
                        <v-list-item-title>Solutions</v-list-item-title>
                    </v-list-item>


                    <v-list-item :href="'/tools'">
                        <v-list-item-title>Tools</v-list-item-title>
                    </v-list-item>


                    <v-list-item @click="scroll('studycase')"  @click.stop="drawer = !drawer">
                        <v-list-item-title>Study Case</v-list-item-title>
                    </v-list-item>

                    <v-list-item :href="'/career'">
                        <v-list-item-title>Career</v-list-item-title>
                    </v-list-item>

                    <v-list-item :href="'/contact'">
                        <v-list-item-title>Contact</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                drawer: false,
                group: null,
                showHeaderLogo: true
            }
        },
        methods: {
            handleScroll: function (evt, el) {
                if (this.isFooterInViewport()) {
                    this.showHeaderLogo = false;
                }else{
                    this.showHeaderLogo = true;
                }
            },
            isFooterInViewport(){
                const el = document.getElementById("footer").getBoundingClientRect();
                return (
                    el.top >= 0 &&
                    el.left >= 0 &&
                    el.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 5) &&
                    el.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        },
    }
</script>

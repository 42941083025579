<template>
    <div>
        <div class="parallax" v-if="$vuetify.breakpoint.xs"></div>
        <section class="career text-center d-flex flex-column align-items-center justify-content-center" id="solution-detail">

            <div class="container">
                <v-breadcrumbs :items="breadcrumbs">
                    <template v-slot:item="{ item }"
                    >
                        <v-breadcrumbs-item
                                :href="item.href"
                                :disabled="item.disabled"
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>


            <div class="desktop-view-solutions-detail">
                <div class="col-md-12 bck-img-right" style="background-position: right -336px bottom -27px">
                    <h4 class="title-solution-detail">Solutions</h4>
                    <div class="row" style="padding-top: 50px;">
                        <div class="col-md-6">
                            <v-img class="img-solution-detail-left" src="/images/solution/solution-leads.png"></v-img>
                        </div>

                        <div class="col-md-6 text-left" style="padding-top: 66px;" id="leads-generation">
                            <div class="container-solution-detail-left bg-black">
                                <div class="row title-img-solution-detail">
                                    <v-img
                                            max-width="85"
                                            max-height="74"
                                            style="margin-right: 13px;"
                                            src="/images/solution-1.png">
                                    </v-img>
                                    <h1>Leads Generation <br> Campaign</h1>
                                </div>
                                <p>
                                    Build funnelling strategy for leads generation across all
                                    available channels to optimise the conversion rate of raw
                                    leads becoming qualified leads
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 bck-img-left">
                    <div class="row" style="padding-top: 50px;">
                        <div class="col-md-6 text-left" style="padding-top: 66px;height: 512px;" id="community-management">
                            <div class="container-solution-detail-right" style="background-position: left">
                                <div class="row title-img-solution-detail  bg-black" style="width: 494px;">
                                    <v-img
                                            max-width="85"
                                            max-height="74"
                                            style="margin-right: 20px;"
                                            src="/images/solution-2.png"></v-img>
                                    <h1>Community <br> Management</h1>
                                </div>
                                <p class=" bg-black">
                                    Monitor and respond to any negative conversation arise in the
                                    social media that talks about the brand in order to maintain
                                    positive share of voices in all digital channels by leveraging
                                    community engagement and social response.
                                </p>
                            </div>
                        </div>

                        <div class="col-md-6" style="margin-top: 25px;">
                            <v-img class="img-solution-detail-right" src="/images/solution/solution-community.png"></v-img>
                        </div>
                    </div>
                </div>


                <div class="col-md-12 bck-img-right" style="background-position: right -336px bottom -27px">
                    <div class="row" style="padding-top: 50px;">
                        <div class="col-md-6" style="padding-top: 55px;">
                            <v-img class="img-solution-detail-left" src="/images/solution/solution-helpdesk.png"></v-img>
                        </div>

                        <div class="col-md-6 text-left" style="padding-top: 66px; height: 580px" id="helpdesk-and-customer-care">
                            <div class="container-solution-detail-left bg-black">
                                <div class="row title-img-solution-detail">
                                    <v-img
                                            max-width="85"
                                            max-height="74"
                                            style="margin-right: 13px;"
                                            src="/images/solution-3.png"></v-img>
                                    <h1>Helpdesk and <br> Customer Care</h1>
                                </div>
                                <p>
                                    Provide a robust workflow of customer care flows
                                    to increase customer satisfaction rate when they complaint
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!--MOBILE VIEW-->
            <div v-if="$vuetify.breakpoint.xs">
                <!--<div style="background-image: url('/images/background-desktop.png'); height: 470px; margin-bottom: 102px;">-->
                <h4 class="title-solution-detail">Solutions</h4>
                <div class="row mx-auto cont-detail-solutions">
                    <div class="mx-auto">
                        <v-img class="img-solution-detail-mobile" src="/images/solution/solution-leads.png"></v-img>
                    </div>

                    <div class="cont-desc-solution mx-auto">
                        <div class="mx-auto container-solution-detail-mobile">
                            <v-row class="title-img-solution-detail">
                                <v-img
                                        max-width="85"
                                        max-height="74"
                                        style="margin-right: 13px;"
                                        src="/images/solution-1.png">
                                </v-img>
                                <h1>Leads Generation <br> Campaign</h1>
                            </v-row>
                            <p>
                                Build funnelling strategy for leads generation across all
                                available channels to optimise the conversion rate of raw
                                leads becoming qualified leads
                            </p>
                        </div>
                    </div>
                </div>
                <!--</div>-->

                <div class="row mx-auto cont-detail-solutions">
                    <!--<div class="col-md-6 text-left">-->
                        <div class="mx-auto">
                            <v-img class="img-solution-detail-mobile" src="/images/solution/solution-community.png"></v-img>
                        </div>

                        <div class="mx-auto cont-desc-solution">
                            <div class="container-solution-detail-mobile">
                                <v-row class="title-img-solution-detail">
                                    <v-img
                                            max-width="85"
                                            max-height="74"
                                            style="margin-right: 13px;"
                                            src="/images/solution-2.png"></v-img>
                                    <h1>Community <br> Management</h1>
                                </v-row>
                                <p>
                                    Monitor and respond to any negative conversation arise in the
                                    social media that talks about the brand in order to maintain
                                    positive share of voices in all digital channels by leveraging
                                    community engagement and social response.
                                </p>
                            </div>
                        </div>
                    <!--</div>-->
                </div>

                <div class="row mx-auto cont-detail-solutions" style="padding-bottom: 46px;">
                    <div class="mx-auto">
                        <v-img class="img-solution-detail-mobile" src="/images/solution/solution-helpdesk.png"></v-img>
                    </div>

                    <div class="mx-auto cont-desc-solution">
                        <div class="container-solution-detail-mobile">
                            <v-row class="title-img-solution-detail">
                                <v-img
                                        max-width="85"
                                        max-height="74"
                                        style="margin-right: 13px;"
                                        src="/images/solution-3.png"></v-img>
                                <h1>Helpdesk and <br> Customer Care</h1>
                            </v-row>
                            <p>
                                Provide a robust workflow of customer care flows to
                                increase customer satisfaction rate when they complaint
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>

</template>

<script>
    export default {
        name: "SolutionDetail",
        metaInfo: {
            title: 'Our Solutions',
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data(){
            return {
                breadcrumbs: [
                    {
                        text: 'Home',
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: 'Solutions',
                        disabled: true,
                        href: '#',
                    },
                ],
            }
        }
    }
</script>

<style scoped>

</style>

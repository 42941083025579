<template>
    <div class="container">
        <div class="parallax"></div>

        <section class="d-flex flex-column mx-auto mb-4" style="max-width: 600px">
            <h1 class="mx-auto title-blog" align="center" style="font-family: 'Montserrat ExtraBold' !important; font-size: 20px">PT Xylo Solusi Indonesia Terms of Service</h1>
            <p style="background-color: black;">
                <ol>
                    <li>
                        Terms<br>
                        By accessing our app, Xylo, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing Xylo. The materials contained in Xylo are protected by applicable copyright and trademark law.
                        <br><br>
                    </li>

                    <li>
                        Use License <br>

                        <ul>
                            <li>
                                Permission is granted to temporarily download one copy of Xylo per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:

                                <ul>
                                    <li>
                                        modify or copy the materials;
                                    </li>
                                    <li>
                                        use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
                                    </li>
                                    <li>
                                        attempt to decompile or reverse engineer any software contained in Xylo;
                                    </li>
                                    <li>
                                        remove any copyright or other proprietary notations from the materials; or
                                    </li>
                                    <li>
                                        transfer the materials to another person or "mirror" the materials on any other server.
                                    </li>
                                </ul>

                            </li>
                            <li>
                                This license shall automatically terminate if you violate any of these restrictions and may be terminated by PT Xylo Solusi Indonesia at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                            </li>
                        </ul>
                        <br><br>
                    </li>

                    <li>
                        Disclaimer<br>
                        <ul>
                            <li>
                                The materials within Xylo are provided on an 'as is' basis. PT Xylo Solusi Indonesia makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                            </li>
                            <li>
                                Further, PT Xylo Solusi Indonesia does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to Xylo.
                            </li>
                        </ul>
                        <br><br>
                    </li>

                    <li>
                        Limitations<br>
                        In no event shall PT Xylo Solusi Indonesia or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Xylo, even if PT Xylo Solusi Indonesia or a PT Xylo Solusi Indonesia authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
                        <br><br>
                    </li>

                    <li>
                        Accuracy of materials<br>
                        The materials appearing in Xylo could include technical, typographical, or photographic errors. PT Xylo Solusi Indonesia does not warrant that any of the materials on Xylo are accurate, complete or current. PT Xylo Solusi Indonesia may make changes to the materials contained in Xylo at any time without notice. However PT Xylo Solusi Indonesia does not make any commitment to update the materials.
                        <br><br>
                    </li>
                    <li>
                        Links<br>
                        PT Xylo Solusi Indonesia has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by PT Xylo Solusi Indonesia of the site. Use of any such linked website is at the user's own risk.
                        <br><br>
                    </li>
                    <li>
                        Modifications<br>
                        PT Xylo Solusi Indonesia may revise these terms of service for its app at any time without notice. By using Xylo you are agreeing to be bound by the then current version of these terms of service.
                        <br><br>
                    </li>
                    <li>
                        Governing Law<br>
                        These terms and conditions are governed by and construed in accordance with the laws of Indonesia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                        <br><br>
                    </li>
                </ol>

                <small style="font-size: 8px; color: #848383">
                    Privacy Policy created with GetTerms.
                </small>
            </p>
        </section>
    </div>

</template>

<script>
    export default {
        name: 'tos',
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Terms of Service',
            // all titles will be injected into this template
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'noindex, nofollow' },
            ]
        },
    }
</script>

<template>
    <section
        class="about-us text-center"
        id="about"
        v-scroll="handleScroll"
    >
        <VideoBackground
            src="/images/Video 414x616.mp4"
            :sources="[
                {src: '/images/Video 1366x525.mp4', res: 1280, autoplay: true, poster: '/images/Video 1366x525.png'},
                {src: '/images/Video 414x616.mp4', res: 900, autoplay: true, poster: '/images/Video 414x616.png'},
                {src: '/images/Video 414x616.mp4', res: 638, autoplay: true, poster: '/images/Video 414x616.png'}
            ]"
            style="max-height: 100%; height: 100vh;"
            preload="none"
        >
            <div class="overlay-text mx-auto my-auto d-block">
                <div class="overlay-text mx-auto my-auto">
                   <v-img
                       v-if="showText"
                       class="logo-about reveal1"
                       :class="showText"
                       src="/images/xylo-logo-2.png"
                       max-width="114"
                       max-heigth="158"
                   >
                   </v-img>
                    <div
                        class="reveal1 title-about1 overlay-text mx-auto my-auto"
                        :class="showText"
                    >
                        {{ title.text }}
                    </div>
                </div>

                <h1
                    class="reveal1 tittle-about2"
                    :class="hideText"
                >{{ description.text }}</h1>
            </div>
        </VideoBackground>
    </section>
</template>
<script>
    import VideoBackground from 'vue-responsive-video-background-player'
    import api from '../../../api/property'

    export default {
        components: { VideoBackground },
        metaInfo: {
            title: 'About Us',
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data: function () {
            return {
                flagText: true,
                scrollPosition: null,
                offsetTop: 0,
                title: {
                    text: '',
                    size: '75px'
                },
                description: {
                    text: '',
                    size: '30px'
                }
            }
        },

        methods: {
            handleScroll: function (evt, el) {
                if (window.scrollY > 10) {
                    this.flagText = false;
                }else{
                    this.flagText = true;
                }
            }
        },

        mounted() {
            window.addEventListener('scroll', this.updateScroll);
        },

        computed: {
            showText() {
                return this.flagText ? 'visible' : '';
            },
            hideText() {
                return !this.flagText ? 'visible' : '';
            }
        },
        created() {
            api.getData("about")
                .then(response => {
                    this.title.text = response.data.data.options.title
                    this.description.text = response.data.data.options.description
                })
                .catch(err => {
                    console.log(err)
                });
        },
    }
</script>

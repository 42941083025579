 <template>
    <section class="contact-us snap-child text-center d-flex flex-column align-items-center justify-center" id="contact">
        <h4 class="title-section d-sm-none d-block">Get In Touch</h4>

        <v-container class="pt-0">
            <v-row>
                <v-col
                    xl="6"
                    lg="6"
                    md="12"
                    sm="12"
                    xs="12"
                >
                    <div id="contact-images">
                        <v-img
                            id="contact-img"
                            src="/images/contact-us.png"
                            style="background-size: cover"
                        >
                        </v-img>
                    </div>
                </v-col>
                <v-col
                    xl="6"
                    lg="6"
                    md="12"
                    sm="12"
                    xs="12"
                >
                    <div class="contact-sub border-gradient-brand mx-auto">
                        <h4 class="title-contact mb-4 d-none d-sm-block">Get In Touch</h4>
                        <div class="contact-sub-detail">
                            <div class="contact-child mx-auto my-auto mt-3">
                                <v-img
                                    class="icon-location contact-icon d-md-inline-block d-xs-block mx-auto"
                                    src="/images/icon-location.png"
                                    max-width="20">
                                </v-img>
                                <label class="contact-address d-inline-block ml-4">Xylo Indonesia<br> {{ address }}</label>
                            </div>
                            <div class="contact-child mt-3">
                                <v-img
                                    class="contact-icon d-md-inline-block d-xs-block mx-auto"
                                    src="/images/icon-call.png"
                                    max-width="20">
                                </v-img>
                                <label class="ml-4">{{ phone_number }}</label>
                            </div>
                            <div class="contact-child mx-auto my-auto mt-3">
                                <v-img
                                    class="contact-icon d-md-inline-block d-xs-block mx-auto"
                                    src="/images/icon-email.png"
                                    max-width="20">
                                </v-img>
                                <label class="ml-4">{{ email }}</label>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>
<script>
    import api from "../../../api/property";

    export default {
        metaInfo: {
            title: 'Contact Us',
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data() {
            return {
                address: '',
                phone_number: '',
                email: '',
            }
        },
        created() {
            api.getData("contact")
                .then(response => {
                    this.address = response.data.data.options.address
                    this.phone_number = response.data.data.options.phone_number
                    this.email = response.data.data.options.email
                })
                .catch(err => {
                    console.log(err)
                });
        },
    }
</script>

<template>
    <section class="form-contact snap-child text-center d-flex flex-column align-items-center justify-content-center" id="form-contact">
        <h4 class="title-section">Drop Us A Message</h4>
        <div class="container">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <div id="form-contact-us" class="mx-auto">
                    <v-row>
                <v-col cols="12">
                    <v-text-field
                        name="name"
                        v-model="name"
                        :rules="nameRules"
                        label="Name"
                        outlined
                        dense
                        background-color="#000000"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="Email"
                        outlined
                        dense
                        background-color="#000000"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="phone"
                        :rules="phoneRules"
                        label="Phone"
                        outlined
                        dense
                        background-color="#000000"
                        required
                    ></v-text-field>
                    <v-textarea
                        v-model="message"
                        :rules="messageRules"
                        label="Tell us how we can help you?"
                        outlined
                        background-color="#000000"
                        required
                        height="100"
                    ></v-textarea>
<!--                    <small>-->
<!--                        This site is protected by reCAPTCHA and the Google-->
<!--                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and-->
<!--                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.-->
<!--                    </small>-->

                    <v-alert v-if="inserted" type="success" class="text-left mt-3" dense>
                        Thank you for your contact, talk to you soon!
                    </v-alert>

                    <v-alert v-if="invalid" type="error" class="text-left mt-2" dense>
                        Sorry there's a problem with your request.
                    </v-alert>

                    <v-btn
                        v-if="!inserted"
                        class="d-block btn-studycase mx-auto"
                        style="color: #ffffff"
                        :loading="loading"
                        :disabled="loading"
                        @click="validate"
                    >
                        SUBMIT
                        <template v-slot:loader>
                            <span>Loading...</span>
                        </template>
                    </v-btn>
                </v-col>
            </v-row>
                </div>
            </v-form>
        </div>
    </section>
</template>
<script>
    import api from '../../../api/contact';
    // import { VueReCaptcha } from 'vue-recaptcha-v3'
    //
    // var sitekey;
    // if(process.env.NODE_ENV === 'production'){
    //     sitekey = '6Ldf6qQZAAAAACQCaUxOtQKr1_MlVE8pnQX6EK9p';
    // }else{
    //     sitekey = '6LcK66QZAAAAAAZSyl3Lpir-YQF3Nvs1Sxt_5nJ3';
    // }

    // For more options see below
    // Vue.use(VueReCaptcha, {
    //     siteKey: sitekey,
    //     loaderOptions: {
    //         useRecaptchaNet: true,
    //         autoHideBadge: true
    //     }
    // });

    export default {
        data: () => ({
            invalid: false,
            valid: false,
            inserted: false,
            loader: null,
            loading: false,
            name: '',
            nameRules: [
                v => !!v || 'Name is required',
                // v => v.length <= 10 || 'Name must be less than 10 characters',
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            phone: '',
            phoneRules: [
                v => !!v || 'Phone is required',
                // v => /.+@.+/.test(v) || 'Phone must be valid',
            ],
            message: '',
            messageRules: [
                v => !!v || 'Message is required',
                // v => /.+@.+/.test(v) || 'Message must be valid',
            ],
        }),
        methods: {
            validate () {
                this.loader = 'loading';

                if(this.$refs.form.validate()){
                    this.recaptcha();
                }
            },

            async recaptcha() {
                // (optional) Wait until recaptcha has been loaded.
                // await this.$recaptchaLoaded()

                // Execute reCAPTCHA with action "contact".
                // const token = await this.$recaptcha('contact')

                let self = this

                // Do stuff with the received token.
                api.create({
                    'name': this.name,
                    'email': this.email,
                    'phone': this.phone,
                    'message': this.message,
                    // 'g-recaptcha': token,
                })
                .then(success => {
                    self.inserted = true
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.errors);
                    }
                });
            },
        },
        watch: {
            loader () {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            },
        },
    }
</script>

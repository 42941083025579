<template>
    <div>
        <div class="parallax"></div>

        <section class="career text-center d-flex flex-column align-items-center justify-content-center" id="career">

            <div class="container">
                <v-breadcrumbs :items="breadcrumbs">
                    <template v-slot:item="{ item }"
                    >
                        <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>

                <h4 class="title-career">Career</h4>
                <p class="description-career"> We are always looking for talented people to join our forces. <br>
                    If you have curiosity and the desire to accomplish great things, we want to meet <br> you. <br>
                    If the position isn't available, no worries, send your CV anyway.</p>
            </div>

            <div class="bg-gradiant d-inline-block">

                <div class="cust-select">
                    <v-select
                        :items="careers"
                        @change="onChange"
                        color="#ffffff"
                        id="select-position"
                        item-text="title"
                        item-value="id"
                        solo
                        v-model="careers_priority"
                    ></v-select>
                </div>

                <p class="d-md-block d-md-block" style="margin-top: 19px">
                    Requirements
                </p>

                <div class="container">
                    <v-row
                        v-if="$vuetify.breakpoint.xsOnly"
                    >
                        <v-col
                            cols="12"
                        >
                            <ul class="text-left">
                                <li v-for="(item,index) in careers_selected">
                                    {{item}}
                                </li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row
                        v-else
                    >
                        <v-col
                            cols="6"
                        >
                            <ul class="text-left">
                                <li v-for="(item, index) in careers_selected.slice(0, divider)" :key="index">
                                    {{item}}
                                </li>
                            </ul>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <ul class="text-left">
                                <li v-for="(item, index) in careers_selected.slice(divider)" :key="index">
                                    {{item}}
                                </li>
                            </ul>
                        </v-col>

                    </v-row>
                </div>

            </div>
            <div class="col-md-12">
                <v-btn class="button-apply-career mx-auto">
                    <a :href="subject_email"
                       style="text-decoration: none; color: #FFFFFF !important;">
                        APPLY NOW
                    </a>
                </v-btn>
            </div>

        </section>
    </div>
</template>

<script>

    export default {
        metaInfo: {
            title: 'Career',
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data() {
            return {
                careers: [],
                careers_selected: [],
                careers_priority: [],
                divider: 0,
                subject_email: '',
                breadcrumbs: [
                    {
                        text: 'Home',
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: 'Career',
                        disabled: true,
                        href: '#',
                    },
                ],
            }
        },
        methods: {
            fetchData: function (page_url) {
                axios
                    .get(page_url || '/api/career/list')
                    .then(response => {
                        this.careers = response.data.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            fetchById: function (id) {
                axios
                    .get('/api/career/show/' + id)
                    .then(response => {
                        this.careers_selected = response.data.data[0].requirement

                        this.changeEmailSubject(response.data.data[0].title);
                        this.divider = Math.ceil(this.careers_selected.length/2);
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            fetchByPriority: function (id) {
                axios
                    .get('/api/career/first')
                    .then(response => {
                        this.careers_priority = response.data.data[0];
                        this.careers_selected = response.data.data[0].requirement;

                        this.divider = this.careers_selected.length/2;
                        this.changeEmailSubject(this.careers_priority.title);
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.divider = Math.ceil(this.careers_selected.length / 2);
            },

            onChange(id) {
                this.fetchById(id);
            },

            changeEmailSubject(subject){
                this.subject_email = "mailto:recruitment@xylo.co.id?subject=[RECRUITMENT] " + subject;
            },

        },
        mounted() {
            this.fetchByPriority();
            this.fetchData();

            this.divider = Math.ceil(this.careers_selected.length / 2);
        },

    }
</script>

<style scoped>

</style>

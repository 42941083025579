<template>
    <v-app>
        <Header></Header>

        <v-content>
            <router-view />
            <Footer></Footer>
        </v-content>
    </v-app>
</template>

<script>
    import Header from "../components/HeaderLayouts";
    import Footer from "../components/FooterLayouts";
    import Breadcrumbs from '../components/Breadcrumbs';

    export default {
        props: {
            source: String,
        },
        components: {Header, Footer, Breadcrumbs},
    }
</script>

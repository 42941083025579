<template>
    <section class="our-tools snap-child text-center d-flex flex-column align-items-center justify-content-center" id="tools">
        <h4 class="title-section">Our Tools</h4>
        <div class="container">
            <div v-for="(item, index) in items">
                <h2 class="subtitle-tools d-sm-block d-md-none">{{ item.title }}</h2>
                <p class="d-sm-block d-md-none">{{ item.description }}</p>
                <div class="row">
                    <div class="col-md-7 img-tools">
                        <v-img :src="item.image.path" :alt="item.image.alt"></v-img>
                    </div>
                    <div class="col-md-5">
                        <div class="tools-decription">
                            <div class="d-inline-block">
                                <v-img src="images/solution-1.png" class="d-inline-block" max-width="86"></v-img>
                                <v-img src="images/solution-2.png" class="d-inline-block" max-width="86"></v-img>
                                <v-img src="images/solution-4.png" class="d-inline-block" max-width="86"></v-img>
                            </div>
                            <h2 class="subtitle-tools d-none d-md-block">Leads Management System</h2>
                            <p class="d-none d-md-block d-sm-none pt-2">A centralized database to collect, follow up, organize and convert our incoming leads into potential deals. Then we can distribute the potential leads to the sales force team to close the deals</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import api from "../../../api/tools";
    export default {
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Our Tools',
            // all titles will be injected into this template
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data(){
            return {
                items:[],
            }
        },
        methods:{
            initialize () {
                api.all().then(response => {
                    this.items = response.data.data;
                }).catch(err => {
                    console.log(err);
                });
            },
            showNext() {
                this.$refs.carousel.next()
            },
            showPrev() {
                this.$refs.carousel.prev()
            },
        },
        mounted () {
            this.initialize()
        },
    }
</script>

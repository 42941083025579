/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');
window.Vue = require('vue');
window.axios = require('axios');

import router from './router'
import store from './store'
import App from './App.vue'

import vuetify from './plugins/vuetify' // path to vuetify export
Vue.use(vuetify);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
// Vue.component('example-component', require('./components/ExampleComponent.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

var mixin = {
    methods: {
        scroll(el_id) {
            if(this.$route.path == '/'){
                const element = document.getElementById(el_id);
                element.scrollIntoView({ behavior: 'smooth' });
            }else{
                this.$router.push('/#'+el_id);
            }
        }
    }
};

Vue.mixin(mixin);

const app = new Vue({
    data: function () {
        return {
            drawer: false,
            group: null,
        }
    },
    vuetify: vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app');

<template id="breadcrumb">
    <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:item="{ item }"
        >
            <v-breadcrumbs-item
                :href="item.href"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
<!--    -->
<!--    <span>-->
<!--    <router-link :to="{name: route.name}" v-if="!route.meta.bcDynamic">-->
<!--      {{route.meta.bcLinkText}} test-->
<!--    </router-link>-->
<!--    <router-link :to="{name: route.name, params: {id: $route.params.id}}" v-if="route.meta.bcDynamic">-->
<!--      <template v-if="value">-->
<!--        {{formattedValue}}-->
<!--      </template>-->
<!--      <template v-if="!value">-->
<!--        {{loadingText}}-->
<!--      </template>-->
<!--    </router-link>-->
<!--  </span>-->
</template>

<script>
    export default {
        data() {
          return {
              breadcrumbs: [
                  {
                      text: 'Home',
                      disabled: false,
                      href: '/',
                  },
                  {
                      text: 'About',
                      disabled: true,
                      href: '#',
                  },
              ],
          }
        },
        props: ['route'],
        beforeCreate() {
            console.log(this.route.meta);
            this.$options.computed.value = function () {
                if (this.route.meta.bcGetter) {
                    return this.$store.getters[this.route.meta.bcGetter]
                } else {
                    return null
                }
            }
        },
        computed: {
            formattedValue() {
                return this.route.meta.bcLinkText(this.value)
            },
            loadingText() {
                const loadingText = this.route.meta.bcLoadingText
                return loadingText ? loadingText : 'Loading...'
            }
        }
    }

</script>

<template>
    <div>
        <div class="parallax"></div>
        <section class="about-detail text-center d-flex flex-column align-items-center justify-content-center"
                 id="about-detail">

                <div class="row snap-child">
                    <div class=" col-md-6 overlay-text text-wrap-xylo">
                        <p class="text-xylo">
                            HOW XYLO <br>
                            WILL HELP <br>
                            YOUR BRAND ?
                        </p>
                    </div>

                    <div class="description-text-about col-md-6 bg-gradiant">
                        <p class="text-xylo-detail">
                            We provide tailored CRM solutions <br>
                            based on our client’s business process to answer their business <br>
                            objectives in transforming leads <br>
                            into potential deals.
                        </p>
                    </div>
                </div>

                <div class="row content-team snap-child">
                    <div class="bg-gradiant">
                        <p class="d-md-block d-md-block text-meet-team">
                            Meet The Teams
                        </p>
                        <div class="col-md-12">
                            <div class="container d-flex mb-5">
                            <span v-if="$vuetify.breakpoint.smAndUp" @click="showPrev" class="slick-arrow-custom float-left my-auto" style="padding-bottom: 49px;">
                                <v-img
                                        src="/images/arrow-prev-white.png"
                                        max-height="43"
                                        max-width="32"
                                >
                                </v-img>
                            </span>

                                <VueSlickCarousel ref="carousel" v-bind="settings">
                                    <div class="card text-center" v-for="team in teams" :key="teams.id" >
                                        <div class="card-body card-solutions content-team-mobile mx-auto px-4">
                                            <v-img
                                                    :src="team.image"
                                                    class="mx-auto"
                                                    max-width="239"
                                            >

                                            </v-img>
                                            <h3 class="card-title">{{ team.name }}</h3>
                                            <h5 class="">{{ team.title }}</h5>
                                        </div>
                                    </div>
                                </VueSlickCarousel>

                                <span v-if="$vuetify.breakpoint.smAndUp" @click="showNext" class="slick-arrow-custom float-right my-auto" style="padding-bottom: 49px;">
                                <v-img
                                        src="/images/arrow-next-white.png"
                                        max-height="43"
                                        max-width="32"
                                >
                                </v-img>
                            </span>
                            </div>
                            <div v-if="$vuetify.breakpoint.xs">
                            <span @click="showPrev" class="d-inline-block mr-3">
                                <v-img
                                        src="/images/arrow-prev-white.png"
                                        max-height="43"
                                        max-width="32"
                                >
                                </v-img>
                            </span>

                                <span @click="showNext" class="d-inline-block ml-3">
                                    <v-img
                                            src="/images/arrow-next-white.png"
                                            max-height="43"
                                            max-width="32"
                                    >
                                    </v-img>
                                </span>
                            </div>

                        </div>

                    </div>
                </div>

        </section>
    </div>

</template>

<script>

    import VueSlickCarousel from 'vue-slick-carousel/dist/vue-slick-carousel.umd.min'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: 'AboutDetail',
        components: { VueSlickCarousel },
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'About Us',
            // all titles will be injected into this template
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data() {
            return {
                teams: [
                    {
                        "id": "1",
                        "name": "Ritchie",
                        "title": "IT Developer",
                        "image": "/images/about/team-img.png"
                    },
                    {
                        "id": "2",
                        "name": "Taufik",
                        "title": "IT Developer",
                        "image": "/images/about/team-img.png"
                    },
                    {
                        "id": "3",
                        "name": "Adhe",
                        "title": "Digital CRM Manager",
                        "image": "/images/about/team-img.png"
                    },
                    {
                        "id": "4",
                        "name": "Poery",
                        "title": "Specialist CRM",
                        "image": "/images/about/team-img.png"
                    },
                    {
                        "id": "5",
                        "name": "Chris",
                        "title": "Response",
                        "image": "/images/about/team-img.png"
                    },
                    {
                        "id": "6",
                        "name": "Pinta",
                        "title": "Response",
                        "image": "/images/about/team-img.png"
                    },
                    {
                        "id": "7",
                        "name": "Andi",
                        "title": "Helpdesk",
                        "image": "/images/about/team-img.png"
                    },

                ],
                settings:{
                    "dots": false,
                    "arrows": false,
                    "dotsClass": "slick-dots custom-dot-class",
                    "edgeFriction": 0.35,
                    "infinite": true,
                    "speed": 500,
                    "slidesToShow": 3,
                    "slidesToScroll": 3,
                    "responsive": [
                        {
                            "breakpoint": 1024,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 3,
                                "infinite": true,
                                "dots": true
                            }
                        },
                        {
                            "breakpoint": 600,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 3,
                                "initialSlide": 3
                            }
                        },
                        {
                            "breakpoint": 480,
                            "settings": {
                                "slidesToShow": 2,
                                "rows" : 2,
                                "slidesToScroll": 2
                            }
                        }
                    ]
                },
            }
        },
        methods: {
            showNext() {
                console.log(this.$refs.carousel.next())
                this.$refs.carousel.next()
            },
            showPrev() {
                console.log('prev')
                this.$refs.carousel.prev()
            },
            fetchData: function (page_url) {
                axios
                    .get(page_url || '/api/career/list')
                    .then(response => {
                        this.careers = response.data.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

        },
        mounted() {
            this.fetchData();
        },

    }
</script>

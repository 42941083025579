<template>
    <div class="container" id="leads-management-system">
        <div class="parallax"></div>

        <section class="studycase-detail text-center d-flex flex-column align-items-center justify-content-center" id="tools-detail">
            <v-breadcrumbs :items="breadcrumbs">
                <template v-slot:item="{ item }"
                >
                    <v-breadcrumbs-item
                        :href="item.href"
                        :disabled="item.disabled"
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>

            <div class="img-tool-detail-background">
                <div class="tools-description-first">
                    <h1>Tools</h1>

                    <div class="title-tools">
                        <div class="d-inline-block">
                            <v-img src="images/solution-1.png" class="d-inline-block tiles-img-tools"></v-img>
                            <v-img src="images/solution-2.png" class="d-inline-block tiles-img-tools"></v-img>
                            <v-img src="images/solution-4.png" class="d-inline-block tiles-img-tools"></v-img>
                        </div>
                        <h1>Leads Management System</h1>
                    </div>

                    <div class="tools-detail-description row-first-desc-tools">
                        <div class="row">
                            <div class=" mx-auto">
                                <v-img
                                        src="images/tools/laptop-tools.png"
                                        class="img-laptop-tools"
                                        ></v-img>
                            </div>
                            <div class="mx-auto text-row-desc">
                                <p class="text-description-tool-detail">
                                    A centralized database to collect, follow up,
                                    organize and convert our incoming leads into potential deals.
                                    Then we can distribute the potential leads to the sales force team to close the deals</p>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="tools-thumbnail">
                    <div class="row">
                        <div class="col-md-6" v-for="detail in details" :key="detail.id" style="margin-bottom: 91px">
                            <div class="card-body px-4">
                                <v-img
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        v-on:mouseover="mouseOverCheck = detail.id"
                                        v-on:mouseout="mouseOverCheck = ''"
                                        v-bind:src="mouseOverCheck === detail.id ? detail.hover : detail.image "
                                        class="mx-auto"
                                        max-width="185"
                                        max-height="185">
                                </v-img>
                                <v-img
                                        v-else
                                        v-bind:src="detail.hover"
                                        class="mx-auto"
                                        max-width="185"
                                        max-height="185">
                                </v-img>

                                <h2 class="card-title subtitle-solution-detail bg-black">{{detail.title}}</h2>
                                <h3 class=" mx-auto description-tools-detail bg-black">
                                    {{detail.description}}</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>

</template>

<script>

    // var width=screen.width;
    // var height=screen.height;
    // alert("Screen Resolution: " + width +"x"+ height);

    export default {
        name: "ToolsDetail",
        metaInfo: {
            title: 'Our Tools',
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data() {
            return {
                message: 'Hover Me!',
                // src: this.myImage,
                mouseOverCheck:'',
                myImage: [],
                details:[
                    {
                        "id" : "1",
                        "title" : "Collect",
                        "image" : "images/tools/icon-collect.png",
                        "hover" : "images/tools/icon-collect-hover.gif",
                        "description" : " With a documented API ready, our system able to connect to any data sources and import your leads" +
                        "                                into the centralized database in our cloud server for further process",

                    },
                    {
                        "id" : "2",
                        "title" : "Follow Up",
                        "image" : "images/tools/icon-call.png",
                        "hover" : "images/tools/icon-FU-hover.gif",
                        "description" : " Our system back office provide a login system " +
                        "for our call agents to access and do follow up call based on the provided data from the data sources",

                    },
                    {
                        "id" : "3",
                        "title" : "Organize",
                        "image" : "images/tools/icon-organize.png",
                        "hover" : "images/tools/icon-organize-hover.gif",
                        "description" : " With the growing lead campaigns that you run on each marketing channel, your leads database are properly managed under our system and we provide" +
                        "tailored made workflows to manage your database and organize it neatly into a segmented database",

                    },
                    {
                        "id" : "4",
                        "title" : "Convert",
                        "image" : "images/tools/icon-convert.png",
                        "hover" : "images/tools/icon-convert-hover.gif",
                        "description" : " Free your hassle in handling big database and let our system automate the process to distribute your segmented" +
                        "potential leads to your sales force and make them easier to close the deal by using our mobile application",

                    }
                ],
                breadcrumbs: [
                    {
                        text: 'Home',
                        disabled: false,
                        href: '/',
                    },
                    {
                        text: 'Tools',
                        disabled: true,
                        href: '#',
                    },
                ],
            }
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>

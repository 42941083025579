<template>
    <div>
        <div class="parallax"></div>

        <section class="d-flex flex-column">
            <!--<div class="img-blog-background">-->
                <v-container>
                    <h1
                            class="mx-auto title-blog"
                            align="left"
                            style="font-family: 'Montserrat ExtraBold' !important;"
                    >COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITAS MERCU BUANA
                    </h1>

                    <v-card-subtitle style="font-family: 'Montserrat ExtraBold' !important;font-size: 17px">
                        31/03/2020 | Agency Culture | Digital Agency | News Events
                    </v-card-subtitle>

                    <v-card
                            align="left"
                            color="black"
                            class="mx-auto"
                    >
                        <v-img
                                class="white--text align-end img-blog-detail"
                                src="/images/blog/visit-mini.png"
                        >
                        </v-img>

                        <v-card-text>
                            <div class="content-text-blog">
                                Untuk melengkapi program MARGOCY (Marcomm Goes to Agency) dari HIMA Marcomm & Advertising Universitas Mercu Buana, Bounche Indonesia dipilih untuk memenuhi program kerja mereka tersebut.

                                Dalam kesempatan ini, perwakilan dari Bounche menjelaskan tentang pentingnya peran media sosial marketing di dunia digital agency. Melalui tema “Learning How to Develop Skills in The Field of Digital Marketing”. Mulai dari social media strategy, implementation-nya, visual yang kece seperti apa, sampai bahas soal KOL (Key Opinion Leader) atau yang biasa kita kenal dengan sebutan influencer.

                                Selama sesi berlangsung, mahasiswa tampak antusias dengan pembahasan tersebut. Bahkan, beberapa mahasiswa mengajukan pertanyaan seperti kendala dalam sosial media serta bagaimana membuat konten yang bagus dan menarik. Tentu saja tema ini sangat cocok untuk bekal mereka di dunia kerja nantinya, apalagi buat yang aktif di dunia digital.
                            </div>
                        </v-card-text>

                        <v-img
                                class="white--text align-end img-blog-detail"
                                src="/images/blog/visit-detail.png"
                        >
                        </v-img>

                        <v-card-text>
                            <div class="content-text-blog">
                                Setelah sesi presentasi selesai, para mahasiswa ini melakukan tur singkat ke dalam kantor Bounche yang dipandu oleh beberapa karyawan. Dalam tur singkat ini, para mahasiswa mendapatkan penjelasan tentang divisi-divisi yang ada beserta job-desc nya. Company visit pun ditutup dengan pemberian cendera mata dari ketua pelaksana kepada pihak Bounche Indonesia.

                                Terima kasih untuk mahasiswa Universitas Mercu Buana berkunjung ke kantor Bounche Indonesia, Sampai bertemu lagi!
                            </div>
                        </v-card-text>
                    </v-card>


                     <v-col cols="12" v-if="$vuetify.breakpoint.smAndUp">
                        <v-row>
                            <v-col cols="6" md="4">
                                <span @click="showPrev" class="slick-arrow-custom float-left my-auto">
                                    <v-row>
                                        <v-img
                                                src="/images/arrow-prev.png"
                                                max-height="43"
                                                max-width="32"
                                        >
                                    </v-img>

                                        <label style="font-family:'Montserrat Regular'; margin-top: 5px; margin-left: 11px;">
                                            Previous Post
                                        </label>
                                    </v-row>
                                </span>
                            </v-col>


                            <v-col cols="6" md="4">
                                <span @click="showPrev" class="slick-arrow-custom float-left my-auto">
                                    <v-row>
                                        <v-btn
                                                style=""
                                                class="btn-studycase btn-see-post-blog">
                                            See All Post
                                        </v-btn>
                                    </v-row>
                                </span>
                            </v-col>


                            <v-col cols="6" md="4">
                                <span @click="showNext" class="slick-arrow-custom float-right my-auto">
                                    <v-row>
                                            <label style="font-family:'Montserrat Regular'; margin-top: 5px; margin-right: 11px;">
                                                Next Post
                                            </label>
                                        <v-img
                                                src="/images/arrow-next.png"
                                                max-height="43"
                                                max-width="32"
                                        >
                                        </v-img>
                                    </v-row>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col v-else
                            cols="12">
                        <v-row>
                            <!--<v-col cols="4">-->
                                <span @click="showPrev" class="slick-arrow-custom float-left" style="margin-right: auto">
                                        <v-img
                                                src="/images/arrow-prev.png"
                                                max-height="43"
                                                max-width="32"
                                        >
                                    </v-img>
                                </span>

                                <v-btn
                                        class="btn-studycase btn-see-post-blog">
                                    See All Post
                                </v-btn>

                                <span @click="showNext" class="slick-arrow-custom float-right" style="margin-left: auto">
                                        <v-img
                                                src="/images/arrow-next.png"
                                                max-height="43"
                                                max-width="32"
                                        >
                                        </v-img>
                                </span>
                            <!--</v-col>-->
                        </v-row>
                    </v-col>
                </v-container>


            <div class="text-center" style="background-color: #3D3D3D; margin-top: 37px">
                <h2 style="font-family: 'Montserrat ExtraBold' !important; margin-top: 21px; margin-bottom: 29px">Related Post</h2>

                <v-col cols="12" v-if="$vuetify.breakpoint.smAndUp">
                    <v-row>
                        <v-col
                                cols="6" md="4">
                            <v-hover>
                                <template v-slot:default="{ hover }">
                                    <v-card
                                            class="mx-auto"
                                            max-width="302"
                                    >
                                        <v-img
                                                src="/images/blog/visit-mini.png"
                                                max-height="161"
                                                max-width="302"
                                        >
                                        </v-img>



                                        <v-fade-transition>
                                            <v-overlay
                                                    v-if="hover"
                                                    absolute
                                                    color="#000000"
                                            >
                                                <div class="container ">
                                                    <p class="hover-text-relate-blog-date">31/03/2020</p>
                                                    <p class="hover-text-relate-blog-title">COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITAS MERCU BUANA</p>
                                                </div>

                                            </v-overlay>
                                        </v-fade-transition>
                                    </v-card>
                                </template>
                            </v-hover>
                        </v-col>


                        <v-col cols="6" md="4">
                            <v-hover>
                                <template v-slot:default="{ hover }">
                                    <v-card
                                            class="mx-auto"
                                            max-width="302"
                                    >
                                        <v-img
                                                src="/images/blog/visit-mini.png"
                                                max-height="161"
                                                max-width="302"
                                        >
                                        </v-img>



                                        <v-fade-transition>
                                            <v-overlay
                                                    v-if="hover"
                                                    absolute
                                                    color="#000000"
                                            >
                                                <div class="container ">
                                                    <p class="hover-text-relate-blog-date">31/03/2020</p>
                                                    <p class="hover-text-relate-blog-title">COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITAS MERCU BUANA</p>
                                                </div>

                                            </v-overlay>
                                        </v-fade-transition>
                                    </v-card>
                                </template>
                            </v-hover>
                        </v-col>


                        <v-col cols="6" md="4">
                            <v-hover>
                                <template v-slot:default="{ hover }">
                                    <v-card
                                            class="mx-auto"
                                            max-width="302"
                                    >
                                        <v-img
                                                src="/images/blog/visit-mini.png"
                                                max-height="161"
                                                max-width="302"
                                        >
                                        </v-img>



                                        <v-fade-transition>
                                            <v-overlay
                                                    v-if="hover"
                                                    absolute
                                                    color="#000000"
                                            >
                                                <div class="container ">
                                                    <p class="hover-text-relate-blog-date">31/03/2020</p>
                                                    <p class="hover-text-relate-blog-title">COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITAS MERCU BUANA</p>
                                                </div>

                                            </v-overlay>
                                        </v-fade-transition>
                                    </v-card>
                                </template>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-col>


                <!--MOBILE HERE-->
                <v-col v-else cols="12">
                    <v-row>
                        <v-col
                                md="12">

                                <template>
                                    <v-card
                                            class="mx-auto"
                                            max-width="302"
                                    >
                                        <v-img
                                                src="/images/blog/visit-mini.png"
                                                max-height="161"
                                                max-width="302"
                                        >
                                        </v-img>




                                            <v-overlay

                                                    absolute
                                                    color="#000000"
                                            >
                                                <div class="container ">
                                                    <p class="hover-text-relate-blog-date">31/03/2020</p>
                                                    <p class="hover-text-relate-blog-title">COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITAS MERCU BUANA</p>
                                                </div>

                                                <!--<v-card-subtitle-->
                                                        <!--class="py-0" style="font-family:'Montserrat Regular' !important;text-align: left;font-size: 15px !important;">-->
                                                    <!---->
                                                <!--</v-card-subtitle>-->
                                                <!--<v-card-title-->
                                                        <!---->
                                                        <!--style=""-->
                                                <!--&gt;</v-card-title>-->
                                                <!--<v-btn>See more info</v-btn>-->
                                            </v-overlay>

                                    </v-card>
                                </template>

                        </v-col>

                    </v-row>
                </v-col>
            </div>
            <!--</div>-->
        </section>
    </div>

</template>

<script>
    export default {
        name: "Blog",
        metaInfo: {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Blog',
            // all titles will be injected into this template
            titleTemplate: '%s | Xylo Indonesia',
            meta: [
                { name: 'robots', content: 'index, follow' },
            ]
        },
        data(){
            return {
                overlay: false,
                items:{
                    image: 'images/blog/visit-mini.png',
                    publish_at: '31/03/2020',
                    title: 'COMPANY VISIT – HIMA ADVERTISING & MARCOMM UNIVERSITA MERCU BUANA',
                    description: 'Melengkapi program MARGOCY (Marcomm Goes to Agency), Bounche dipilih untuk menjelaskan tema Learn How to Develop Skills in The Field of Digital Marketing.',
                    slug: '',
                }
            }
        }
    }
</script>

<style scoped>

</style>
